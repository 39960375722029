import styled, { css } from "styled-components";
import {
  fontSize_BASE,
  fontSize_SM,
  fontSize_XS,
  fontSize_XXL,
  fontSize_XXXL,
} from "../customFonts";
import { themeGradient } from "../constants";

export const Banner = styled.div`
  background: ${themeGradient};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  color: white;

  h2 {
    padding: 15px 0;
    font-weight: 600;

    @media (max-width: 768px) {
      padding-bottom: 20px;
    }
  }

  h2,
  p {
    text-align: center;
  }

  @media (max-width: 768px) {
    p {
      padding: 0 10px;
    }

    section {
      width: 100%;
    }
  }
`;

export const CountableWrapper = styled.div`
  h5 {
    padding-top: 30px;
    ${fontSize_XXXL};
    font-style: normal;
    font-weight: 400;
    line-height: 53px;
    text-align: center;
    color: #ffffff;

    @media (max-width: 768px) {
      ${fontSize_XXL};
      line-height: initial;
    }
  }
`;

export const NumberColumn = styled.div`
  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 89px;
    text-align: center;
    color: #ffdd79;
    padding: 30px 0;

    @media (max-width: 768px) {
      ${fontSize_XXXL};
      line-height: initial;
    }
  }

  p {
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    ${fontSize_SM};
  }
`;

export const Form = styled.div`
  background-color: white;
  border-radius: 24px;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;

  .row {
    padding-bottom: 20px;
  }

  input,
  textarea {
    background: #eeeeee;
  }
`;
