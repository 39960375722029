import React from "react";
import { SectionHeading } from "../styles";

export default function Primaryheading(props: any) {
  return (
    <SectionHeading>
      <div className="d-flex align-items-center pb-4">
        {props.primaryText && <b>{props.primaryText}</b>}
      </div>
      {props.tagline && <p className="tagline">{props.tagline}</p>}
    </SectionHeading>
  );
}
