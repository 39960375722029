import React, { useState } from "react";
import Header from "./UIComponents/Header";
import Footer from "./UIComponents/Footer";

function AppContainer({ props }: any) {
  return (
    <>
      <div className={"main"}>
        <Header />
        <Footer />
      </div>
    </>
  );
}

export default AppContainer;
