import React, { useEffect } from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { StyledGrid } from "./styles";

export default function MobileappDevelopment() {
  const I5 = require("../../../assets/images/services/5.svg");
  const I6 = require("../../../assets/images/services/6.svg");
  const I7 = require("../../../assets/images/services/7.svg");
  const I8 = require("../../../assets/images/services/8.svg");

  return (
    <div>
      <Section className="bg-gray mb-5">
        <div className="container py-5 animate__animated animate__fadeIn">
          <PrimaryHeading
            primaryText="Transform Your Ideas into Powerful Mobile Applications"
            tagline="Your one-stop destination for innovative mobile app development solutions. With the world going mobile, having a robust and user-friendly app has become essential for businesses to stay ahead in the digital landscape. "
          />
        </div>
      </Section>

      <Section>
        <div className="container">
          <PrimaryHeading primaryText="Our Mobile App Development Services Include:" />
          <StyledGrid className="row pt-0 animate__animated animate__fadeInUp">
            <div className="col-lg-4">
              <div className="images-part">
                <img src={I5} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Native App Development</h4>
                <p className="margin-0">
                  Harness the full potential of iOS and Android platforms with
                  our native app development expertise. We create bespoke apps
                  that leverage the unique capabilities of each platform,
                  delivering unmatched performance and user experience.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-8 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Cross-Platform Development</h4>
                <p className="margin-0">
                  Reach a broader audience without compromising on quality with
                  our cross-platform app development services. Our skilled
                  developers use frameworks like React Native to build apps that
                  run seamlessly on multiple devices.
                </p>
              </div>
            </div>
            <div className="col-lg-4 order-0 order-sm-1">
              <div className="images-part">
                <img src={I6} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-4">
              <div className="images-part">
                <img src={I7} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> E-Commerce Apps</h4>
                <p className="margin-0">
                  Capitalize on the booming e-commerce industry with a
                  feature-rich and secure mobile shopping app. Our e-commerce
                  apps are designed to drive conversions and provide a
                  delightful shopping experience.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-8 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>App Maintenance and Support</h4>
                <p className="margin-0">
                  We don't stop at app deployment. Our dedicated support team
                  ensures that your app runs smoothly, addressing any issues
                  promptly, and keeping it up-to-date with the latest features.
                </p>
              </div>
            </div>{" "}
            <div className="col-lg-4 order-0 order-sm-1">
              <div className="images-part">
                <img src={I8} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>
        </div>
      </Section>
    </div>
  );
}
