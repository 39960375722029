import React from "react";
import { BgHeading, Section } from "../styles";

const Contact = () => {
  const BG = require("../../assets/images/8.svg");
  const Img9 = require("../../assets/images/9.svg");

  return (
    <BgHeading>
      <div className="d-none d-sm-block">
        <img src={BG} alt="" />
      </div>
      <h5>Have questions - connect with us</h5>
      <div className="container">
        <Section className="d-flex justify-content-center  pt-0 pt-sm-5">
          <div className="d-flex justify-content-center">
            <div className="row margin-0">
              <div className="col-lg-12 padding-0">
                <div className="contact-address">
                  <div className="mb-5">
                    <h2 className="title pb-20">Get in touch</h2>
                    <p className="margin-0">
                      Thank you for considering our IT consulting company for
                      your business needs. If you have any questions, comments
                      or inquiries, please feel free to contact us using the
                      information provided below. We would love to hear from you
                      and discuss how we can help your business succeed.
                    </p>
                    <p>
                      We understand that every business has unique needs and
                      challenges, which is why we offer customised solutions
                      tailored to meet your specific requirements. Whether
                      you're looking for assistance with data management,
                      software development, or technology strategy, our team of
                      experts is here to help.
                    </p>
                    <p>
                      We pride ourselves on delivering top-notch services that
                      enable businesses to stay ahead of the competition. Our
                      team of dedicated professionals has years of experience
                      and a deep understanding of the latest technological
                      advancements, ensuring that we provide innovative
                      solutions that meet your business needs.
                    </p>
                    <p>
                      So, whether you're a small business or a large
                      corporation, we are here to help. Contact us today to
                      learn more about our services and how we can assist you in
                      achieving your business goals.
                    </p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-map-marker me-3" />
                    <div className="address-text">
                      #101, Patrikanagar, Madhapur, Hyderabad 500085..{" "}
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-phone me-3" />
                    <div className="address-text">
                      <a href="tel:(+088)589-8745">(+088) 589-8745</a>
                    </div>
                  </div> */}
                  <div className="d-flex align-items-center mb-5">
                    <i className="fa fa-envelope me-3" />
                    <div className="address-text">
                      <a href="mailto:info@newdevex.com">info@newdevex.com</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 padding-0">
                <div className="rs-contact main-home office-modify1 pt-50 pb-50 md-pt-50 md-pb-50">
                  <div className="container">
                    <div className="row margin-0">
                      <div className="col-lg-6 padding-0 office-buliding" />
                      <div className="col-lg-6 padding-0">
                        <div className="contact-section contact-style2">
                          <div className="sec-title mb-60">
                            <h2 className="title">Contact us</h2>
                          </div>
                          <div className="contact-wrap">
                            <div id="form-messages" />
                            <form
                              id="contact-form"
                              method="post"
                              action="mailer.php"
                            >
                              <fieldset>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="name"
                                      name="name"
                                      placeholder="Name"
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="email"
                                      name="email"
                                      placeholder="E-Mail"
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="phone"
                                      name="phone"
                                      placeholder="Phone Number"
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 mb-30">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="Website"
                                      name="subject"
                                      placeholder="Your Website"
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-12 mb-35">
                                    <textarea
                                      className="form-control"
                                      id="message"
                                      name="message"
                                      placeholder="Your message Here"
                                      required
                                      defaultValue={""}
                                    />
                                  </div>
                                </div>
                                <div className="btn-part">
                                  <div className="form-group mb-0">
                                    <input
                                      className="readon submit"
                                      type="submit"
                                      defaultValue="Submit Now"
                                    />
                                  </div>
                                </div>
                              </fieldset>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </Section>
      </div>
    </BgHeading>
  );
};

export default Contact;
