import React, { useState, FunctionComponent } from "react";
import { HeaderWrapper, MenuList } from "./styles";
import Router from "../../routes";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";

interface ResponsiveMenuProps {
  onClose: () => void; // Define the type for onClose prop
}

const Button = loadable(() => import("../../DesignSystem/Button"));

const ResponsiveMenu: FunctionComponent<ResponsiveMenuProps> = (props) => {
  return (
    <>
      <MenuList className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarScrollingDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Company
            <i className="fa fa-chevron-down" />
          </a>

          <div
            className="dropdown-menu"
            aria-labelledby="navbarScrollingDropdown"
          >
            <div className="d-flex justify-content-center sub-menu-wrapper">
              <ul className="left">
                <li>
                  <Link to="/EmpowerDevelopment" onClick={props.onClose}>
                    Empower development
                  </Link>
                </li>
                <li>
                  <Link to="/AboutUs" onClick={props.onClose}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/Clients" onClick={props.onClose}>
                    Clients
                  </Link>
                </li>
                <li>
                  <Link to="/Contact" onClick={props.onClose}>
                    Questions?
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarScrollingDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Services
            <i className="fa fa-chevron-down" />
          </a>

          <div
            className="dropdown-menu"
            aria-labelledby="navbarScrollingDropdown"
          >
            <div className="d-flex justify-content-center sub-menu-wrapper">
              <ul className="left">
                <li>
                  <Link to="/WebDevelopment" onClick={props.onClose}>
                    Web development
                  </Link>
                </li>
                <li>
                  <Link to="/MobileappDevelopment" onClick={props.onClose}>
                    Mobile App development
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/Ecommerce" onClick={props.onClose}>
                    e-Commerce development
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/ERP" onClick={props.onClose}>
                    ERP Software
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/UIDesign" onClick={props.onClose}>
                    UI/UX Design Services
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/DigitalMarketing" onClick={props.onClose}>
                    Digital Marketing
                  </Link>
                </li>{" "}
                {/* <li>
                  <Link to="/CloudServices" onClick={props.onClose}>
                    Cloud Services
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/DigitalTransformation" onClick={props.onClose}>
                    Digital Transformation
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarScrollingDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Development
            <i className="fa fa-chevron-down" />
          </a>

          <div
            className="dropdown-menu"
            aria-labelledby="navbarScrollingDropdown"
          >
            <div className="d-flex justify-content-center sub-menu-wrapper">
              <ul className="left">
                <li>
                  <Link to="/BigData" onClick={props.onClose}>
                    Big Data & Hadoop
                  </Link>
                </li>
                <li>
                  <Link to="/DataScience" onClick={props.onClose}>
                    Data Science
                  </Link>
                </li>
                <li>
                  <Link to="/Snowflake" onClick={props.onClose}>
                    Machine learning
                  </Link>
                </li>
                <li>
                  <Link to="/AIStrategy" onClick={props.onClose}>
                    Artificial Strategy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarScrollingDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Knowledge Transfer
            <i className="fa fa-chevron-down" />
          </a>

          <div
            className="dropdown-menu"
            aria-labelledby="navbarScrollingDropdown"
          >
            <div className="d-flex justify-content-center sub-menu-wrapper">
              <ul className="left">
                <li>
                  <Link to="/CaseStudies" onClick={props.onClose}>
                    Case Studies
                  </Link>
                </li>
                <li>
                  <Link to="/Videos" onClick={props.onClose}>
                    Videos
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <li>
          <Link to="/Careers" className="nav-item" onClick={props.onClose}>
            Careers
          </Link>
        </li>

        <li>
          <Link to="/Contact">
            <Button
              title="Contact"
              // className="ms-3"
              variant="secondary"
              isHideIcon={true}
              onClick={props.onClose}
            >
              Reach us
            </Button>
          </Link>
        </li>
      </MenuList>
    </>
  );
};

export default ResponsiveMenu;
