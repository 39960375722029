import React, { useEffect } from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { StyledGrid } from "./styles";

export default function WebDevelopment() {
  const P1 = require("../../../assets/pages/1.png");
  const I1 = require("../../../assets/images/services/1.svg");
  const I2 = require("../../../assets/images/services/2.svg");
  const I3 = require("../../../assets/images/services/3.svg");
  const I4 = require("../../../assets/images/services/4.svg");

  return (
    <div>
      <Section className="bg-gray mb-5">
        <div className="container py-5 animate__animated animate__fadeIn">
          <PrimaryHeading
            primaryText="Expert Web Development Services for Your Business"
            tagline="Are you looking to establish a strong online presence and drive business growth? Look no further! At Newdevex, we offer top-notch web development services tailored to meet your unique business needs."
          />
        </div>
      </Section>

      <Section>
        <div className="container">
          <PrimaryHeading primaryText="Our Web Development Services Include:" />
          <StyledGrid className="row pt-0 animate__animated animate__fadeInUp">
            <div className="col-lg-6">
              <div className="images-part">
                <img src={I1} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Custom Web Development</h4>
                <p className="margin-0">
                  Our expert developers will create a custom website from
                  scratch, designed specifically to showcase your brand and
                  offerings. We focus on user experience, ensuring seamless
                  navigation and intuitive interfaces.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> Responsive Web Design</h4>
                <p className="margin-0">
                  With the majority of users accessing the internet on mobile
                  devices, it's crucial to have a responsive website. We ensure
                  that your site looks great and functions flawlessly on all
                  devices, providing an optimal user experience.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="images-part">
                <img src={I2} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6">
              <div className="images-part">
                <img src={I3} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> Content Management Systems (CMS)</h4>
                <p className="margin-0">
                  Take control of your website's content with our intuitive CMS
                  solutions. Update, edit, and manage your content effortlessly,
                  without any technical knowledge.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> Website Maintenance and Support</h4>
                <p className="margin-0">
                  Our services don't end with the website launch. We offer
                  ongoing maintenance and support to ensure your website
                  continues to perform optimally, providing a seamless
                  experience for your visitors.
                </p>
              </div>
            </div>{" "}
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="images-part">
                <img src={I4} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>
        </div>
      </Section>
    </div>
  );
}
