import styled, { css } from "styled-components";
import { sideBar, arrowDimentions } from "../variables";
import { fontSize_BASE, fontSize_XXL, fontSize_XXXXL } from "./customFonts";
import { device } from "./responsiveBreakpoints";

interface Selected {
  isOpen?: boolean;
}

export const flexCenter = css`
  align-items: center;
  justify-content: center;
`;

export const sectionPadding = css`
  padding: 30px 0;
`;

export const alignedCenter = css`
  align-items: center;
`;

export const displayFlex = css`
  display: flex;
`;

export const cursorPointer = css`
  cursor: pointer;
`;

export const Padding0y50x = css`
  padding: 0px 50px;
`;

export const fontThin = css`
  font-weight: 200;
`;

export const fontRegular = css`
  font-weight: 400;
`;

export const fontMedium = css`
  font-weight: 500;
`;

export const fontBold = css`
  font-weight: 700;
`;

export const FontLight = styled.b`
  font-weight: 400;
  color: ${(props) => props.theme.primary.fontLight};
`;

export const FlexCenter = styled.div`
  ${flexCenter};
  ${displayFlex};
`;

export const DisplayCenter = styled.div`
  ${flexCenter};
  ${displayFlex};
  flex: 1;
`;

export const rowSpaceBetween = styled.div`
  ${displayFlex};
  flex-flow: column;
`;

export const ApplicationContainer = styled.div`
  width: 100%;
  ${displayFlex};
  -webkit-flex-flow: column;
  flex-flow: column;
  height: 100%;

  .piechart-wrapper {
    border-bottom: solid;
  }
`;

export const MainContainer = styled.div`
  overflow: auto;
`;

export const AdView = styled.div`
  width: ${sideBar};
  background-color: #ccc;
  ${displayFlex};
  -webkit-flex-flow: column;
  flex-flow: column;
  background-image: linear-gradient(to right, #7073c7, #3f51b5);
`;

export const ArrowView = styled.div<Selected>`
  position: fixed;
  right: ${(props) => (props.isOpen ? "200px" : "0")};
  bottom: 0;
  background-color: ${(props) => props.theme.primary.whiteColor};
  border: solid 2px ${(props) => props.theme.primary.color};
  border-radius: 100px;
  width: ${arrowDimentions};
  height: ${arrowDimentions};
  ${displayFlex};
  ${flexCenter};
  ${cursorPointer};
  z-index: 9999;
`;

export const HomeImageView = styled.div`
  ${displayFlex};
  ${flexCenter};

  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
`;

export const TitleHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    width: 100% !important;
  }

  h5 {
    ${fontMedium};
    text-transform: capitalize;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 5px;
    color: ${(props) => props.theme.primary.THEME};
  }

  .title {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 0;
    color: ${(props) => props.theme.primary.THEME};
    font-style: italic;

    span {
      ${fontBold};
      font-style: normal;
      ${fontBold};
      font-family: "Playfair Display", serif;
      letter-spacing: 1.2px;
      color: ${(props) => props.theme.primary.SECONDARY_THEME};
    }
  }
`;

export const Sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;

  @media (max-width: 767px) {
    position: relative;
  }
`;

export const SpecialFont = styled.b`
  color: ${(props): string => props.theme.primary.SECONDARY_THEME};
  font-size: 25px;
`;

export const Button = styled.button`
  background-color: #e8292e;
  gap: 6px;
  width: 156px;
  height: 48px;
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 36px;
  padding: 12px 26px;
`;

export const Section = styled.div`
  ${sectionPadding};
`;

export const SectionHeading = styled.div`
  ${fontSize_XXXXL};
  font-weight: 500;
  font-size: 32px;
  color: black;
  padding: 0 0 10px 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  b {
    padding-right: 10px;
    text-align: center;
  }

  .tagline {
    text-align: center;
    width: 80%;
    font-size: 20px;
    color: black;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const Subscribe = styled.div`
  width: 30%;
  position: relative;

  p {
    text-align: center;
  }

  input {
    border-radius: 20px;
    height: 36px;
  }

  button {
    position: absolute;
    right: -4px;
    top: 37px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BgHeading = styled.div`
  img {
    width: 100%;
  }

  h5 {
    font-style: normal;
    font-weight: 600;
    ${fontSize_XXXXL};
    line-height: 64px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
      color: #000;
      position: relative;
      left: 0;
      top: auto;
      transform: translate(0%, 0%);
      display: flex;
      justify-content: center;
    }
  }

  .about-main-content {
  }
`;

export const SlickSlideContainer = styled.div`
  .slick-slide {
    padding: 0 12px;
  }

  .slick-slide img {
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  .slick-next,
  .slick-prev {
    background: #496ced;
    border-radius: 100%;

    @media (max-width: 768px) {
      display: none !important;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    .item {
      width: 200px;
      padding: 8px;
      border: solid 1px #eee;
      border-radius: 8px;
      display: flex;
      align-items: center;
      height: 200px;
      object-fit: contain;

      @media ${device.mobileL} {
        width: 100%;
        height: auto;
      }

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
`;
