import React, { FunctionComponent, useState } from "react";
import { GridBox } from "./styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { Link } from "react-router-dom";
// import { SectionHeading } from "../../styles";

const FourGrid: FunctionComponent = (props: any) => {
  const S1 = require("../../../assets/images/s1.svg");
  const S2 = require("../../../assets/images/s2.svg");
  const S3 = require("../../../assets/images/s3.svg");
  const S4 = require("../../../assets/images/s4.svg");
  const S5 = require("../../../assets/images/s5.svg");
  const S6 = require("../../../assets/images/s6.svg");
  const S7 = require("../../../assets/images/s7.svg");
  const S8 = require("../../../assets/images/s8.svg");

  const services = [
    {
      title: "Web Development",
      src: S1,
      linkTo: "/WebDevelopment",
    },
    {
      title: "Mobile App Development",
      src: S2,
      linkTo: "/MobileappDevelopment",
    },
    {
      title: "e-Commerce Development",
      src: S3,
      linkTo: "/Ecommerce",
    },
    {
      title: "UI/UX Design",
      src: S4,
      linkTo: "/UIDesign",
    },
    {
      title: "ERP Software",
      src: S5,
      linkTo: "/ERP",
    },
    {
      title: "Digital Marketing",
      src: S6,
      linkTo: "/DigitalMarketing",
    },
    {
      title: "Cloud Services",
      src: S7,
    },
    {
      title: "Digital Transformation",
      src: S8,
    },
  ];

  return (
    <div className={"container"}>
      <PrimaryHeading
        primaryText="Our Most Prominent Services"
        secondaryText=""
        tagline="Newdevex provides end-to-end data and tool learning analytics services."
      />
      <section>
        <div className="row">
          {services.map((item: any) => {
            return (
              <div className={"col-6 col-sm-6 col-md-3"}>
                <Link
                  to={item.linkTo}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <GridBox>
                    <img src={item.src} />
                    <h5>{item.title}</h5>
                  </GridBox>
                </Link>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default FourGrid;
