import React, { useEffect } from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { StyledGrid } from "./styles";

export default function DigitalMarketing() {
  const I21 = require("../../../assets/images/services/21.svg");
  const I22 = require("../../../assets/images/services/22.svg");
  const I23 = require("../../../assets/images/services/23.svg");
  const I4 = require("../../../assets/images/services/4.svg");

  return (
    <div>
      <Section className="bg-gray mb-5">
        <div className="container py-5 animate__animated animate__fadeIn">
          <PrimaryHeading
            primaryText="Empower Your Brand's Success with Data-Driven Digital Marketing Solutions"
            tagline="We are a results-oriented digital marketing agency that leverages cutting-edge strategies to elevate your brand, expand your online reach, and maximize your ROI."
          />
        </div>
      </Section>

      <Section>
        <div className="container">
          <PrimaryHeading primaryText="Our Web Development Services Include:" />
          <StyledGrid className="row pt-0 animate__animated animate__fadeInUp">
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="images-part">
                <img src={I21} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Strategic Approach</h4>
                <p className="margin-0">
                  We believe in the power of strategy. Our digital marketing
                  experts conduct comprehensive research and analyze market
                  trends to develop data-driven strategies that align with your
                  business objectives.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Multi-Channel Expertise</h4>
                <p className="margin-0">
                  In the ever-evolving digital landscape, it's crucial to have a
                  presence across multiple platforms. From search engines to
                  social media and beyond, we excel in executing integrated
                  campaigns that keep your brand at the forefront of your
                  customers' minds.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="images-part">
                <img src={I22} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Lead Generation and Conversion</h4>
                <p className="margin-0">
                  Attracting traffic is just the beginning. Our strategies are
                  designed to convert visitors into leads and leads into loyal
                  customers. We optimize every touchpoint to ensure a seamless
                  user journey that inspires action.
                </p>
              </div>
            </div>{" "}
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="images-part">
                <img src={I23} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>
        </div>
      </Section>
    </div>
  );
}
