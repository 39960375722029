import React from "react";
import { TwoGridBox } from "./styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";

export default function TwoGrid() {
  const I6 = require("../../../assets/images/6.svg");

  const services = [
    {
      title: "IT Strategy Consulting",
      para: "We work closely with our clients to understand their business goals and objectives, and develop an IT strategy that aligns with their vision",
      src: I6,
    },
    {
      title: "IT Infrastructure Consulting",
      para: "Our team of experts can help you design, implement, and manage your IT infrastructure, ensuring that it is secure, scalable, and reliable",
      src: I6,
    },
    {
      title: "Cloud Consulting",
      para: "We can help you leverage the power of the cloud to improve efficiency, reduce costs, and enable collaboration.",
      src: I6,
    },
    {
      title: "Cybersecurity Consulting",
      para: "Our cybersecurity experts can help you protect your business from cyber threats by implementing robust security measures and providing ongoing monitoring and support.",
      src: I6,
    },
  ];

  return (
    <div className={"container"}>
      <PrimaryHeading
        primaryText="Explore Our Industry knowledge"
        secondaryText=""
      />
      <section>
        <div className="row">
          <h5 className="d-flex justify-content-center text-center mb-5">
            We offer complete solutions in data engineering, cloud technologies,
            devops, artificial intelligence, cybersecurity, QA, coding,
            Salesforce, and JAVA.
          </h5>
          {services.map((item: any) => {
            return (
              <div className={"col-12 col-sm-6"}>
                <TwoGridBox>
                  <div className="d-flex align-items-center justify-content-start w-100">
                    <img src={item.src} className="pe-3" />
                    <h5>{item.title}</h5>
                  </div>

                  <p>{item.para}</p>
                </TwoGridBox>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}
