import React, { useEffect } from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { StyledGrid } from "./styles";

export default function Ecommerce() {
  const I9 = require("../../../assets/images/services/9.svg");
  const I10 = require("../../../assets/images/services/10.svg");
  const I11 = require("../../../assets/images/services/11.svg");
  const I12 = require("../../../assets/images/services/12.svg");

  return (
    <div>
      <Section className="bg-gray mb-5">
        <div className="container py-5 animate__animated animate__fadeIn">
          <PrimaryHeading
            primaryText="Empower Your Business with a High-Performing e-Commerce Platform"
            tagline="Your trusted partner for crafting exceptional e-commerce solutions that drive sales and elevate your online business. In today's competitive digital landscape, having a robust and user-friendly e-commerce platform is crucial to succeed in the online marketplace.."
          />
        </div>
      </Section>

      <Section>
        <div className="container">
          <PrimaryHeading primaryText="Our E-Commerce Development Services Include" />
          <StyledGrid className="row pt-0 animate__animated animate__fadeInUp">
            <div className="col-lg-6">
              <div className="images-part">
                <img src={I9} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Custom Online Store Development</h4>
                <p className="margin-0">
                  We understand that no two businesses are the same. Our expert
                  developers create custom online stores that perfectly align
                  with your brand identity and offerings. From eye-catching
                  designs to seamless navigation, we ensure your online store
                  stands out from the crowd.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> User-Focused Design</h4>
                <p className="margin-0">
                  We prioritize user experience in every aspect of our
                  e-commerce development process. Our talented designers create
                  intuitive and visually appealing interfaces that engage
                  visitors and maximize conversions.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="images-part">
                <img src={I10} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6">
              <div className="images-part">
                <img src={I11} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> Secure Payment Gateway Integration</h4>
                <p className="margin-0">
                  Trust and security are paramount in e-commerce. Our developers
                  integrate reliable and secure payment gateways, providing your
                  customers with a safe and hassle-free checkout process.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> Inventory and Order Management</h4>
                <p className="margin-0">
                  Managing inventory and orders can be complex. Our e-commerce
                  solutions come equipped with efficient inventory and order
                  management systems, enabling you to streamline operations and
                  meet customer demands.
                </p>
              </div>
            </div>{" "}
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="images-part">
                <img src={I12} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>
        </div>
      </Section>
    </div>
  );
}
