import React, { useState, FunctionComponent } from "react";
import { HeaderWrapper, MenuList, PrimaryContainer } from "./styles";
import Router from "../../routes";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import ResponsiveMenu from "./ResponsiveMenu";

const Button = loadable(() => import("../../DesignSystem/Button"));

const Header: FunctionComponent = () => {
  const logoImg = require("../../../assets/images/logo.svg");
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const toggleNavigation = () => {
    setIsNavigationOpen((prevIsNavigationOpen) => !prevIsNavigationOpen);
  };

  const closeNavigation = () => {
    setIsNavigationOpen(false);
  };

  return (
    <>
      <PrimaryContainer>
        <div className="container d-flex justify-content-between align-content-center">
          <p>
            Office hours: 10:00 AM to 07:00 PM
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Hyderabad, India.
          </p>
          <p>Email Us: newdevex@gmail.com</p>
        </div>
      </PrimaryContainer>
      <HeaderWrapper>
        <nav className="navbar navbar-expand-lg w-100 container py-3">
          <div className="container-fluid d-flex justify-content-between">
            <Link to="/Home">
              <img src={logoImg} alt="logo" className={"logo"} />
            </Link>

            <button
              className="navbar-toggler e"
              type="button"
              onClick={toggleNavigation}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`main-menu ${isNavigationOpen ? "open" : ""}`}>
              <MenuList className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Company
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/EmpowerDevelopment">
                            Empower development
                          </Link>
                        </li>
                        <li>
                          <Link to="/AboutUs">About Us</Link>
                        </li>
                        <li>
                          <Link to="/Clients">Clients</Link>
                        </li>
                        <li>
                          <Link to="/Contact">Questions?</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Services
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/WebDevelopment">Web development</Link>
                        </li>
                        <li>
                          <Link to="/MobileappDevelopment">
                            Mobile App development
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/Ecommerce">e-Commerce development</Link>
                        </li>{" "}
                        <li>
                          <Link to="/ERP">ERP Software</Link>
                        </li>{" "}
                        <li>
                          <Link to="/UIDesign">UI/UX Design Services</Link>
                        </li>{" "}
                        <li>
                          <Link to="/DigitalMarketing">Digital Marketing</Link>
                        </li>{" "}
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Development
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/BigData">Big Data & Hadoop</Link>
                        </li>
                        <li>
                          <Link to="/DataScience">Data Science</Link>
                        </li>
                        <li>
                          <Link to="/Snowflake">Machine learning</Link>
                        </li>
                        <li>
                          <Link to="/AIStrategy">Artificial Strategy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Knowledge Transfer
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/CaseStudies">Case Studies</Link>
                        </li>
                        <li>
                          <Link to="/Videos">Videos</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li>
                  <Link to="/Careers" className="nav-item">
                    Careers
                  </Link>
                </li>

                <li>
                  <Link to="/Contact">
                    <Button
                      title="Contact"
                      // className="ms-3"
                      variant="secondary"
                      isHideIcon={true}
                    >
                      Reach us
                    </Button>
                  </Link>
                </li>
              </MenuList>
            </div>

            {isNavigationOpen && (
              <div className="sidebar pt-4">
                <Link to="/Home">
                  <img src={logoImg} alt="logo" className={"logo"} />
                </Link>
                <ResponsiveMenu onClose={closeNavigation} />
              </div>
            )}
          </div>
        </nav>
      </HeaderWrapper>

      <Router />
    </>
  );
};

export default Header;
