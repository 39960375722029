import React, { useEffect } from "react";
import { Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Technologies from "./Technologies";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function AWS() {
  const P1 = require("../../assets/pages/1.png");

  return (
    <div>
      {/* Breadcrumbs Start */}
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img4 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Amazon Web Services (AWS)" />

              <span className="sub-text">
                If you are familiar with Amazon Web Services, well and good. But
                if you’re still searching for some answers, you’ve landed on the
                right page. We are here to guide you through and deliver all the
                latest knowledge. Having extensive exposure to AWS, we can
                assist you with the most advanced AWS services and guidance.{" "}
              </span>
            </div>
          </div>
        </div>
      </Section>
      {/* Breadcrumbs End */}
      {/* About Section Start */}
      <Section>
        <div className="rs-about py-0">
          <div className="container custom">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 md-mb-50">
                <div className="images-part">
                  <img src={P1} alt="Images" className="w-100" />
                </div>
              </div>
              <div className="col-lg-6 pl-60 md-pl-15">
                <div className="sec-title">
                  <h1>AWS</h1>
                  <p className="margin-0 pt-15">
                    Data retention and buying space on the cloud can be highly
                    expensive. But the need for buying cloud space for
                    businesses can’t be undermined as well. This is where VSIT
                    LLC can help your business.
                  </p>
                  <p className="margin-0 pt-15">
                    With our unmatched expertise and familiarity with AWS, we
                    can render assistance to derive costeffective benefits. Our
                    goal is to serve you better with AWS while also guiding you
                    at every step.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* About Section End */}

      <Section className="bg-gray">
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <h2 className="title pb-22">
                    Choose Newdevex for better assistance with AWS. Contact us
                    now and say goodbye to your worries!
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* Services Section End */}
      <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Here’s how we can help" />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    {/* <div class="services-icon">
                                <img src="assets/images/services/style3/4.png" alt="Services">
                            </div> */}
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Migration <br />
                          Guide
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        With the advancement in technologies, companies are now
                        shifting from traditional practices to modern ones. We
                        help you migrate from Hadoop to AWS by laying down a
                        suitable and analytical migration guide.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Trusted Data <br /> Platform
                        </a>
                      </h5>
                      <p className="services-txt">
                        {" "}
                        We can help you build a better data platform online.
                        With our free cloud automation tool, you can build
                        infrastructure and eliminate errors on the go.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Data Pipelines with A <br /> WS Cloud Formation
                        </a>
                      </h5>
                      <p className="services-txt">
                        Data pipelines aren’t easy to manage and the complexity
                        increases with heavy data. To make things simple, the
                        AWS cloud formation tool creates your cloud services in
                        a more flexible fashion.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    {/* <div class="services-icon">
                                <img src="assets/images/services/style3/1.png" alt="Services">
                            </div> */}
                    <div className="services-content">
                      <h5 className="services-title">
                        <a href="">
                          Low-Cost AWS <br /> Cloud DataOps
                        </a>
                      </h5>
                      <p className="services-txt">
                        Utilising the data storage services on AWS cloud can get
                        expensive. Our company can help you save the expense on
                        platform administration costs.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Services Section End */}

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Data Pipelines with AWS Cloud Formation" />
              <p className="mt-5">
                AWS Data Pipeline is a web service that helps you reliably
                process and move data between different AWS compute and storage
                services, as well as on-premises data sources, at specified
                intervals. ... AWS Data Pipeline also allows you to move and
                process data that was previously locked up in on-premises data
                silos.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Trusted Data Platform" />
              <p className="mt-5">
                AWS Data Pipeline is an ETL service that you can use to automate
                the movement and transformation of data and You can create your
                workflow using the AWS Management console or use the AWS command
                line interface or API to automate the process of creating and
                managing pipelines
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
