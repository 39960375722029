import React from "react";
import { Banner } from "./styles";
import loadable from "@loadable/component";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";

const Button = loadable(() => import("../DesignSystem/Button"));

export default function HeaderBanner() {
  const B1 = require("../../assets/images/1.svg");
  const B2 = require("../../assets/images/2.svg");
  const B3 = require("../../assets/images/3.svg");
  const Web2Img = require("../../assets/lottie/web2.json");
  const Web1 = require("../../assets/lottie/web1.json");
  const Web2 = require("../../assets/lottie/web-development.json");
  const Web3 = require("../../assets/lottie/cloud-computing.json");

  // const CloudComputing = require("../../assets/lottie/cloud-computing.json");

  return (
    <Banner>
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center row">
          <h2 className="animate__animated animate__fadeInUp col-12 col-sm-8">
            <div className="text-theme-2">Tech-driven Transformation</div>
            <br /> Elevate Your Business with Comprehensive IT Solutions
          </h2>
          <div className="col-12 col-sm-6">
            <p className="animate__animated animate__fadeInUp">
              With our expertise spanning{" "}
              <b>
                <i>Web Development</i>
              </b>{" "}
              , e-Commerce, Digital marketing, Android application development,
              and ERP software solutions, we empower businesses to thrive in the
              ever-evolving digital landscape.
            </p>
          </div>

          <section className="py-3 animate__animated animate__fadeIn">
            <div className="d-flex justify-content-center align-items-center row">
              <div className="d-flex flex-column col-9 col-sm-4 align-items-center">
                <Lottie animationData={Web2Img} loop={true} />
                <Link to="/WebDevelopment">
                  {" "}
                  <Button variant="secondary" isHideIcon={true}>
                    Web Development
                  </Button>
                </Link>
              </div>
              <div className="d-flex flex-column col-9 col-sm-1 align-items-center"></div>
              <div className="d-flex flex-column col-9 col-sm-4 align-items-center">
                <Lottie animationData={Web1} loop={true} />
                <Link to="/MobileappDevelopment">
                  {" "}
                  <Button variant="secondary" isHideIcon={true}>
                    Mobile App Development
                  </Button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Banner>
  );
}
