import styled from "styled-components";
import { flexCenter } from "../../styles";
import {
  fontSize_BASE,
  fontSize_SM,
  fontSize_XL,
  fontSize_XXL,
} from "../../customFonts";

// interface Selected {
//   isSmallHeight?: boolean;
// }

export const GridBox = styled.div`
  padding: 18px;
  ${flexCenter};
  background: #ffffff;
  border: 1px solid #eee;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  margin-bottom: 26px;
  height: 230px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.03);
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  h5,
  h2 {
    ${fontSize_BASE};
    line-height: 24px;
    text-align: center;
    font-weight: 600;
  }

  h2 {
    color: #000;
    text-align: left;
    ${fontSize_XXL};
    padding-bottom: 14px;
  }

  p {
    text-align: center;

    @media (max-width: 768px) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      width: 100%;
    }
  }

  b {
    ${fontSize_BASE};
    font-weight: 900;
  }

  img {
    padding-bottom: 26px;
    width: 90px;
  }

  /* &:hover {
    background-color: #2d4a8a2e;
    cursor: pointer;
    color: ${(props) => props.theme.primary.THEME};
  } */

  h5 {
    font-weight: 500;
  }
`;
