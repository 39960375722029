import React, { FunctionComponent, useState } from "react";
import { SectionHeading, SlickSlideContainer } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import Slider from "react-slick";

export default function WorkWith(props: any) {
  const SuchiERP = require("../../assets/images/clients/suchierp.svg");
  const GoldenSoftwareSolution = require("../../assets/images/clients/goldensoftwaresolution.svg");
  const aidatamonks = require("../../assets/images/clients/aidatamonks.svg");
  const allesxr = require("../../assets/images/clients/allesxr.svg");
  const arverity = require("../../assets/images/clients/arverity.svg");
  const bharathvadde = require("../../assets/images/clients/bharathvadde.svg");
  const deltalake = require("../../assets/images/clients/deltalake.svg");
  const helpaneedy = require("../../assets/images/clients/helpaneedy.png");
  const hiringdaffodil = require("../../assets/images/clients/hiringdaffodil.svg");
  const hiringsvsit = require("../../assets/images/clients/hiringsvsit.svg");
  const opensoft = require("../../assets/images/clients/opensoft.svg");
  const savrinda = require("../../assets/images/clients/savrinda.svg");
  const suchitaconsulting = require("../../assets/images/clients/suchita-consulting.png");
  const taxcrediloans = require("../../assets/images/clients/taxcrediloans.svg");
  const v4u = require("../../assets/images/clients/v4u.svg");
  const vsitllc = require("../../assets/images/clients/vsitllc.png");
  const BTS = require("../../assets/images/clients/btsitaly.svg");
  const SKSOFT = require("../../assets/images/clients/sksoft.svg");
  const DatalabInfotech = require("../../assets/images/clients/datalab-infotech.svg");
  const SriniSoft = require("../../assets/images/clients/srinisoft.svg");
  const VSITSYSTEMSINC = require("../../assets/images/clients/vsit-systems-inc.svg");
  const Sarvamayi = require("../../assets/images/clients/sarvamayi.svg");
  const LRPOWERSYSTEMS = require("../../assets/images/clients/lrpowersystems.svg");
  const BROWSEPROSPECTSLLC = require("../../assets/images/clients/browseprospectsllc.svg");

  const services = [
    {
      src: LRPOWERSYSTEMS,
    },
    {
      src: BROWSEPROSPECTSLLC,
    },
    {
      src: BTS,
    },
    {
      src: SuchiERP,
    },
    {
      src: GoldenSoftwareSolution,
    },
    {
      src: aidatamonks,
    },
    {
      src: allesxr,
    },
    {
      src: Sarvamayi,
    },
    {
      src: deltalake,
    },
    {
      src: helpaneedy,
    },
    {
      src: arverity,
    },
    {
      src: savrinda,
    },
    {
      src: vsitllc,
    },
    {
      src: suchitaconsulting,
    },

    {
      src: bharathvadde,
    },
    {
      src: opensoft,
    },
    {
      src: hiringsvsit,
    },

    {
      src: SKSOFT,
    },
    {
      src: DatalabInfotech,
    },
    {
      src: SriniSoft,
    },
    {
      src: VSITSYSTEMSINC,
    },
    {
      src: v4u,
    },
    {
      src: taxcrediloans,
    },
    {
      src: hiringdaffodil,
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className={"container"}>
      <SlickSlideContainer>
        <PrimaryHeading
          primaryText="Companies We Work with"
          secondaryText=""
          tagline="Our customers are critical to how we think, perform, and grow as we work towards empowering businesses through simplified solutions & services"
        />

        <div className="py-4 list">
          {services.map((item: any) => {
            return (
              <div className="item">
                <img src={item.src} />
              </div>
            );
          })}
        </div>
      </SlickSlideContainer>
    </div>
  );
}
