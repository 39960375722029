import React from "react";
import { Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";

export default function Dataiku() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img9 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <Primaryheading primaryText="Dataiku - A Tool for Expertise" />
              <span className="sub-text">
                Dataiku - a contemporary sensation is recognized as a podium
                that systematizes not only the use of Artificial Intelligence
                (AI) but also data that bring people together to consign
                incredible business results. It is an integrated virtual
                environment where users work as they please.
              </span>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div>
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-12">
                <div className="sec-title3">
                  <Primaryheading
                    primaryText="                                    What makes us different?
                            "
                  />

                  <p className="margin-0 pb-40">
                    We provide the best Dataiku guidance to our clients. Dataiku
                    implementation and execution is made easy with Newdevex Inc
                    .{" "}
                  </p>
                  <ul className="icon-item">
                    <li>
                      <p className="list-text">
                        Our professional strategists build insightful guide to
                        train your team for various Dataiku models.
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        We deliver 24*7 support and ensure that your Dataiku is
                        always running smoothly. Our administration techniques
                        are highly-effective.{" "}
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        We employ various AI tools to deliver the performance
                        and service our clients desire. Our useful AI strategies
                        keep your machine learning road map running.{" "}
                      </p>
                    </li>
                  </ul>
                  <p className="margin-0 pb-40">
                    For more information and assistance, you can contact VSIT
                    LLC .{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-100 pb-10">
          <div className="container">
            <div className="sec-title mb-60">
              <Primaryheading primaryText=" Mechanize Analytics for a Self-Serve Business" />

              <p className="services-txt mt-30 mb-30">
                Dataiku rapidly automates business progression by faultless
                scheduling processes. It empowers the business to automate
                analytical tasks that are mostly recurring, without relying on
                IT teams or data science. This helps people to concentrate on
                more impactful and fertile data assignments.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-20 pb-10">
          <div className="container">
            <div className="sec-title mb-60">
              <Primaryheading primaryText="Web Apps and More" />
              <p className="services-txt mt-30 mb-30">
                With numerous ways to develop applications, organizations can
                construct applications with the insights predicted by them to
                drive good customer experiences and furthermore improve their
                proficiency. JavaScript, R Shiny, Bokeh, Dash and many more are
                just to name a few among those leading frameworks that Dataiku
                supports.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
