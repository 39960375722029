import styled from "styled-components";
import { flexCenter } from "../../styles";
import { fontSize_BASE } from "../../customFonts";

export const TwoGridBox = styled.div`
  padding: 18px;
  ${flexCenter};
  background: #ffffff;
  border: 1px solid #eee;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  margin-bottom: 26px;
  height: 230px;

  @media (max-width: 767px) {
    width: 100%;
  }

  h5 {
    ${fontSize_BASE};
    line-height: 24px;
    text-align: center;
    color: black;
    font-weight: 600;
    color: black;
  }

  img {
    padding-bottom: 26px;
  }

  &:hover {
    background-color: #2d4a8a2e;
    cursor: pointer;
  }
`;
