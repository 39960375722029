import styled, { css } from "styled-components";
import { theme } from "../../../theme";

export const StyledGrid = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;

  .sec-title {
    text-align: center;

    h4 {
      padding-bottom: 12px;
      font-weight: 600;
      color: ${(props: any) => props.theme.primary.THEME};
    }
  }
`;
