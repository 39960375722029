import React from "react";
import HeaderBanner from "./HeaderBanner";
import FourGrid from "../UIComponents/FourGrid";
import { Section } from "../styles";
import WorkWith from "../UIComponents/WorkWith";
import { CountableWrapper, NumberColumn, Form } from "./styles";
import TwoGrid from "../UIComponents/TwoGrid";
import Button from "../DesignSystem/Button";
import { Subscribe } from "../styles";

export default function Home() {
  const Bg1 = require("../../assets/images/5.svg");
  const FormBG = require("../../assets/images/7.svg");

  return (
    <>
      <HeaderBanner />

      <Section className="animate__animated animate__fadeInUp mt-5">
        <FourGrid />
      </Section>

      <Section className="mt-5">
        <WorkWith />
      </Section>

      <Section
        className="pb-5 bg-cover mt-5"
        style={{
          background: "#1b1717",
          backgroundPosition: "center",
          width: "100%",
          backgroundRepeat: "repeat-x",
          backgroundSize: "contain",
          height: "auto",
        }}
      >
        <div className="container">
          <CountableWrapper>
            <h5>At Newdevex, customer satisfaction is our ultimate goal</h5>
          </CountableWrapper>
          <div className="row pb-4">
            <div className="col-12 col-sm-6">
              <NumberColumn>
                <h5>23+</h5>
                <p>
                  With an impressive portfolio of 17 successful projects under
                  our belt, we have solidified our position as industry experts
                  in delivering exceptional web solutions.
                </p>
              </NumberColumn>
            </div>
            <div className="col-12 col-sm-6">
              <NumberColumn>
                <h5>97%</h5>
                <p>We're proud to have a staggering 97% of happy customers.</p>
              </NumberColumn>
            </div>
          </div>
        </div>
      </Section>

      <Section className="mt-5">
        <TwoGrid />
      </Section>

      <Section
        className="pb-5 bg-cover mt-5"
        style={{
          backgroundImage: `url(${FormBG})`,
          backgroundPosition: "center",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "auto",
        }}
      >
        <div className="container">
          <CountableWrapper>
            <h5>
              Collaborated With Us to Take the First Step <br /> on the Journey
              Toward Success.
            </h5>
          </CountableWrapper>
          <div className="row pb-4 pt-5 align-items-center">
            <div className="col-12 col-sm-7">
              <>
                <p className="text-white" style={{ lineHeight: 2 }}>
                  We are the ideal solution for enterprises and startups because
                  of our knowledge and expertise in providing system updates,
                  hosting services, security, and maintenance of data platforms.
                  Take advantage of our specialists and advanced technology that
                  investigates options that most supplies won't
                </p>
                <p className="text-white" style={{ lineHeight: 2 }}>
                  We are the ideal solution for enterprises and startups because
                  of our knowledge and expertise in providing system updates,
                  hosting services, security, and maintenance of data platforms.
                  Take advantage of our specialists and advanced technology that
                  investigates options that most supplies won't
                </p>
              </>
            </div>
            <div className="col-12 col-sm-5">
              <Form>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      placeholder="Company Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <input
                      type="text"
                      placeholder="Contact Number"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <textarea
                      placeholder="Write a short message here."
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12 d-flex justify-content-center">
                    <Button>Let's connect</Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
