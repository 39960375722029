import React, { useEffect } from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { StyledGrid } from "./styles";

export default function UIDesign() {
  const I17 = require("../../../assets/images/services/17.svg");
  const I18 = require("../../../assets/images/services/18.svg");
  const I19 = require("../../../assets/images/services/19.svg");
  const I20 = require("../../../assets/images/services/20.svg");

  return (
    <div>
      <Section className="bg-gray mb-5">
        <div className="container py-5 animate__animated animate__fadeIn">
          <PrimaryHeading
            primaryText="Elevate Your Digital Experience with Stunning UI/UX Design Solutions"
            tagline="A creative hub for innovative UI/UX design that breathes life into digital experiences. We are passionate about crafting visually captivating and user-centric designs that leave a lasting impression on your audience. "
          />
        </div>
      </Section>

      <Section>
        <div className="container">
          <PrimaryHeading primaryText="Our UI/UX Design Services" />
          <StyledGrid className="row  pt-0 animate__animated animate__fadeInU">
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Wireframing and Prototyping</h4>
                <p className="margin-0">
                  Before the final design takes shape, we meticulously craft
                  wireframes and prototypes to visualize the user journey and
                  make informed refinements.
                </p>
              </div>
            </div>{" "}
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="images-part">
                <img src={I20} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="rowp">
            <div className="col-lg-6">
              <div className="images-part">
                <img src={I17} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Web Interface Design</h4>
                <p className="margin-0">
                  We create captivating web designs that not only captivate your
                  audience but also drive measurable results. From engaging
                  landing pages to interactive websites, we make a lasting
                  impact.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> Mobile App Design</h4>
                <p className="margin-0">
                  Stand out in the crowded app market with visually appealing
                  and user-friendly mobile app designs. We focus on delivering
                  seamless experiences that keep users coming back for more.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="images-part">
                <img src={I18} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6">
              <div className="images-part">
                <img src={I19} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> User Research and Testing</h4>
                <p className="margin-0">
                  Gain valuable insights into user behavior and preferences
                  through our thorough user research and testing services. This
                  data-driven approach informs our design decisions, resulting
                  in designs that resonate with your target audience.
                </p>
              </div>
            </div>
          </StyledGrid>
        </div>
      </Section>
    </div>
  );
}
