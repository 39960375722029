import React, { useEffect } from "react";
import { Section } from "../../styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";
import { StyledGrid } from "./styles";

export default function ERP() {
  const I13 = require("../../../assets/images/services/13.svg");
  const I14 = require("../../../assets/images/services/14.svg");
  const I15 = require("../../../assets/images/services/15.svg");
  const I16 = require("../../../assets/images/services/16.svg");

  return (
    <div>
      <Section className="bg-gray mb-5">
        <div className="container py-5 animate__animated animate__fadeIn">
          <PrimaryHeading
            primaryText="Streamline Your Business Operations with Our Powerful ERP Software"
            tagline="Our comprehensive ERP systems are designed to streamline and optimize your operations, giving you a competitive edge in today's dynamic business landscape."
          />
        </div>
      </Section>

      <Section>
        <div className="container">
          <PrimaryHeading primaryText="Our Web Development Services Include:" />
          <StyledGrid className="row pt-0 animate__animated animate__fadeInUp">
            <div className="col-lg-6">
              <div className="images-part">
                <img src={I13} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>End-to-End Integration</h4>
                <p className="margin-0">
                  Our ERP software seamlessly integrates all your business
                  processes, from finance and human resources to sales,
                  inventory, and beyond. Say goodbye to disconnected systems and
                  embrace a unified platform that fosters efficiency and
                  collaboration.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Real-Time Insights</h4>
                <p className="margin-0">
                  Make data-driven decisions with ease. Our ERP software
                  provides real-time insights into key performance indicators,
                  empowering you with the information you need to stay ahead of
                  the competition.
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="images-part">
                <img src={I14} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6">
              <div className="images-part">
                <img src={I15} alt="Images" className="w-100" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title pt-4 pt-sm-0">
                <h4> Enhanced Productivity</h4>
                <p className="margin-0">
                  By automating repetitive tasks and streamlining processes, our
                  ERP software frees up valuable time for your employees to
                  focus on strategic initiatives and value-added activities.
                </p>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid className="row">
            <div className="col-lg-6 order-1 order-sm-0">
              <div className="sec-title pt-4 pt-sm-0">
                <h4>Secure and Reliable</h4>
                <p className="margin-0">
                  Trust is the foundation of any successful ERP system. Our
                  software is built with robust security measures to protect
                  your sensitive business data and ensure uninterrupted
                  operations.
                </p>
              </div>
            </div>{" "}
            <div className="col-lg-6 order-0 order-sm-1">
              <div className="images-part">
                <img src={I16} alt="Images" className="w-100" />
              </div>
            </div>
          </StyledGrid>
        </div>
      </Section>
    </div>
  );
}
